<template>
  <div class="content">
    <Nav></Nav>
    <div class="local-con">
      <div class="local-title">
        <div class="text">
          {{ localName }}目前共有稿件:{{ postCount }}
          <span style="margin-left: 20px"> 图片数: {{ postPicCount }}</span>
        </div>
        <div class="page">
          <div class="page-container">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="20"
              :hide-on-single-page="true"
              layout="prev, pager, next, jumper"
              :total="downTotal"
            ></el-pagination>
          </div>
        </div>
      </div>
      <div class="local-list">
        <div class="local-left">
          <div class="local-name" @click="onGoReset">昭觉分区:</div>
          <div class="local-con">
            <div class="local-del" v-for="(k, shiIndex) in typeRootList" :key="k.id">
              <div class="local-shi" @click="onclickshi(k.id, shiIndex, k.name)" :class="{ red: clickIndex == shiIndex }">
                {{ k.name }}
              </div>
              <div class="loca-xian" v-if="k.dropShow">
                <div class="xian-list" v-for="(h, xianIndex) in k.xianList" :key="h.id" @click="onClickxian(h.id, xianIndex, h.name)" :class="{ red: clickxian == xianIndex }">
                  <div class="xian-name"><span class="el-icon-location-information" style="margin-right: 10px"></span>{{ h.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="local-right">
          <div v-for="p in postList" :key="p.id" class="whole_box">
            <div class="whole_box_content">
              <div class="img" :style="{ backgroundImage: `url(` + p.imageURL + `)` }" @click="onLookPost(p.id)"></div>
              <div class="title_s" @click="onLookPost(p.id)">
                {{ p.title }}
              </div>
              <div class="eventTime">{{ p.eventTime }}</div>
            </div>
          </div>
          <div class="page">
            <div class="page-container">
              <el-pagination
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-size="20"
                :hide-on-single-page="true"
                layout="prev, pager, next, jumper"
                :total="downTotal"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'
export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      localName: '余姚地区',
      typeRootList: [], // 地址的root
      defaultProps: {
        label: 'name',
        children: 'children',
      },
      postList: [], // 稿件列表
      page: 1,
      currentPage: 1,
      downTotal: 1,
      postCount: '', // 当前的稿件数
      postPicCount: '', // 当前的图片数
      query: {},
      dropShow: false, // 下拉加载展示
      clickId: '', // 被点击的ID
      clickIndex: -1, // 未被点击
      clickxian: -1,
    }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      var thiz = this
      RD.addr()
        .id(10)
        .downAddress()
        .then((data) => {
          data.forEach((k) => {
            k.children = []
            k.dropShow = false
          })
          data.xianList = []
          thiz.typeRootList = data
        })
      thiz.query.addrId = 10
      thiz.query.storeIds = [1]
      RD.pure()
        .query()
        .query(thiz.query, thiz.page, 20)
        .then((items) => {
          items.list.forEach((i) => {
            i.imageURL = RD.pic().id(i.frontPicId).mImage_URL()
          })
          thiz.postCount = items.pager.total
          thiz.postList = items.list
          thiz.currentPage = items.pager.currentPage
          thiz.downTotal = items.pager.total
        })

      RD.query()
        .queryPic(thiz.query)
        .then((data) => {
          console.log('pic123', data)
          thiz.postPicCount = data.pager.total
        })
    },
    handleCurrentChange() {},
    onGoReset() {
      this.reload()
    },
  },
}
</script>

<style lang="less" scoped>
.local-con {
  width: 100%;
  min-height: calc(100vh - 47.5rem);
}
@media all and (min-width: 801px) {
  .local-con {
    width: 120rem;
    margin: 0 auto;
    min-height: 58.5rem;
    .local-title {
      height: 5rem;
      display: flex;
      position: relative;
      .text {
        height: 5rem;
        line-height: 5rem;
        font-weight: bold;
        font-size: 1.3rem;
      }
      .page {
        position: absolute;
        right: 3rem;
        top: 1rem;
      }
    }
    .local-list {
      display: flex;
      height: auto;
      .local-left {
        width: 25%;
        border: 0.1rem solid #ccc;
        .local-name {
          font-size: 1.8rem;
          text-align: left;
          height: 3.5rem;
          margin-left: 0.5rem;
        }
        .local-con {
          height: 100%;
          width: 90%;
          margin-left: 2rem;
          font-size: 1.5rem;
          .local-del {
            height: auto;
            width: 80%;
            .local-shi {
              height: 3rem;
              text-align: left;
              cursor: pointer;
            }
            .local-shi.red {
              color: red;
            }
            .loca-xian {
              height: auto;
              margin-left: 2rem;
              text-align: left;
              .xian-list {
                height: 3rem;
                cursor: pointer;
              }
              .xian-list.red {
                color: red;
              }
            }
          }
        }
      }
      .local-right {
        width: 75%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .whole_box {
          width: 42.9rem;
          height: 22.7rem;
          box-sizing: border-box;
          margin: 1rem;
          position: relative;
          box-shadow: 0.4rem 0.4rem 0.6rem rgba(0, 0, 0, 0.3);
          background-color: #f1f1f1;
          overflow: hidden;
          .whole_box_content {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            .img {
              width: 100%;
              height: 75%;
              transition: all 0.6s;
              display: block;
              z-index: 10;
              background-position: 50%;
              background-repeat: no-repeat;
              background-size: contain;
              background-color: #f6f6f6;
              cursor: pointer;
            }
            .title_s {
              height: 8%;
              transition: all 0.5s linear;
              width: 90%;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              padding-top: 10px;
              text-align: left;
              font-size: 1.4rem;
              left: 0;
              right: 0;
              margin-left: 1rem;
              line-height: 2.5rem;
              cursor: pointer;
            }
            .eventTime {
              height: 3rem;
              line-height: 3rem;
              text-align: left;
              margin-left: 1rem;
            }
            .time {
              height: 10%;
              position: absolute;
              left: 13.5rem;
              .count {
                height: 2rem;
                display: inline-block;
              }
              .tag {
                display: inline-block;
                height: 2rem;
                width: 2rem;
                cursor: pointer;
                margin-left: 1rem;
                // background: no-repeat url(../assets/tagup.png);
              }
              .like {
                height: 2rem;
                display: inline-block;
                cursor: pointer;
                margin-left: 1rem;
                width: 2rem;
                // background: no-repeat url(../assets/like.png);
              }
              .com {
                height: 2rem;
                display: inline-block;
                cursor: pointer;
                margin-left: 1rem;
                width: 2rem;
                // background: no-repeat url(../assets/comment.png);
              }
            }
          }
        }
        .page {
          position: relative;
          height: 6rem;
          width: 100%;
          .page-container {
            position: absolute;
            bottom: 1rem;
            right: 2rem;
          }
        }
      }
    }
  }
}
</style>